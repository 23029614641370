:root {
  --color-primary: #2455a8; // Blue
  --color-secondary: #1b2330; //Dark Blue (intro and email sign up background)
  --color-secondary-dark-1: #171e2a; //Dark Blue (main background)
  --color-secondary-dark-2: #20293b; //Dark Blue (testimonials background)
  --color-secondary-light: #0a1423; //Dark Blue (footer background)
  --color-tertiary-light: hsl(176, 68%, 64%);
  --color-tertiary-dark: hsl(198, 60%, 50%);

  --color-background: #f3f2ee;

  --color-black: #000;
  --color-white: #fff;
  --color-grey: #666;
  --color-grey-dark: #444;
  --color-black-shadow: rgba(0, 0, 0, 0.3);
  --btn-gredient: linear-gradient(
    to right,
    var(--color-tertiary-light),
    var(--color-tertiary-dark)
  );
  --shadow-primary: 0.8rem 0.8rem 4rem -1rem var(--color-black-shadow);

  --color-black-08: rgba(0, 0, 0, 0.2);

  --color-fb-logo: #3c5a99;
  --color-tw-logo: #1da1f2;
  --color-insta-logo: #c13584;

  --color-linkedin-logo: #0072b1;
  --color-github-logo: #171515;
  --color-youtube-logo: #ff0000;

  --navbar-height: 65px; // Navbar height
}

$bp-xl: 90em; // 1440px
$bp-lg: 75em; // 1200
$bp-md: 56.25em; // 900
$bp-sm: 37.5em; // 600
$bp-xs: 25em; // 400

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;

  @media only screen and (max-width: $bp-md) {
    font-size: 55%;
  }

  @media (max-width: $bp-sm) {
    font-size: 45%;
  }

  @media (max-width: $bp-xs) {
    font-size: 40%;
  }
}

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.6;
  font-size: 1.4rem;
  color: var(--color-black);
  background-color: var(--color-white);
}

// the app class to have min-height 100vh - the navbar
#routeContainer {
  min-height: 100vh;
}

#root {
  height: 100%;
}
blockquote {
  border-left: 2px solid #ddd;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  color: #aaa;
  font-style: italic;
}

blockquote[dir="rtl"] {
  border-left: none;
  padding-left: 0;
  padding-right: 10px;
  border-right: 2px solid #ddd;
}
pre {
  padding: 10px;
  background-color: #eee;
  white-space: pre-wrap;
}

:not(pre) > code {
  font-family: monospace;
  background-color: #eee;
  padding: 3px;
}
