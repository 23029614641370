.co-nav {
  padding: 1rem 4rem;
  background-color: var(--color-primary);
  position: fixed;
  width: 100%;
  height: var(--navbar-height);
  top: 0;
  z-index: 99;

  &__brand--text {
    color: var(--color-white);
    text-decoration: none;
  }
}

.user-avatar {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.co-nav__brand--img {
  max-height: 50px;
  height: 100%;
}
