.account-page {
  padding: 10rem 0;
  .react-tel-input .form-control {
    width: 100%;
  }
}

// fix mantine select menu zindex
.mantine-InputWrapper-root .select__menu {
  z-index: 2!important;
}