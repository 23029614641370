@-webkit-keyframes flash {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}

@keyframes flash {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}


.animated-background {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: 2;
  animation-iteration-count: 2;
  -webkit-animation-name: flash;
  animation-name: flash;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  -webkit-background-size: 800px 104px;
  position: relative;
}

.round-box{
  width: 50px;
  margin: 10px;
  height: 50px;
  border-radius: 100%;
}

.flash-text-sm {
  width: 40%;
  height: 2rem;
}

.flash-text-md {
  width: 50%;
  height: 2rem;
}

.flash-text-lg {
  width: 70%;
  height: 2rem;
}

.flash-text-xl {
  width: 100%;
  height: 2rem;
}
